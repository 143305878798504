[
  {
    "name": "아프가니스탄",
    "enName": "Afghanistan",
    "country": "AF",
    "nationNumber": "+93",
    "flagUrl": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"
  },
  {
    "name": "알바니아",
    "enName": "Albania",
    "country": "AL",
    "nationNumber": "+355",
    "flagUrl": "https://flagcdn.com/al.svg"
  },
  {
    "name": "알제리",
    "enName": "Algeria",
    "country": "DZ",
    "nationNumber": "+213",
    "flagUrl": "https://flagcdn.com/dz.svg"
  },
  {
    "name": "아메리칸사모아",
    "enName": "American Samoa",
    "country": "AS",
    "nationNumber": "+1684",
    "flagUrl": "https://flagcdn.com/as.svg"
  },
  {
    "name": "안도라",
    "enName": "Andorra",
    "country": "AD",
    "nationNumber": "+376",
    "flagUrl": "https://flagcdn.com/ad.svg"
  },
  {
    "name": "앙골라",
    "enName": "Angola",
    "country": "AO",
    "nationNumber": "+244",
    "flagUrl": "https://flagcdn.com/ao.svg"
  },
  {
    "name": "앤티가 바부다",
    "enName": "Antigua and Barbuda",
    "country": "AG",
    "nationNumber": "+1268",
    "flagUrl": "https://flagcdn.com/ag.svg"
  },
  {
    "name": "아르헨티나",
    "enName": "Argentina",
    "country": "AR",
    "nationNumber": "+54",
    "flagUrl": "https://flagcdn.com/ar.svg"
  },
  {
    "name": "아르메니아",
    "enName": "Armenia",
    "country": "AM",
    "nationNumber": "+374",
    "flagUrl": "https://flagcdn.com/am.svg"
  },
  {
    "name": "아루바",
    "enName": "Aruba",
    "country": "AW",
    "nationNumber": "+297",
    "flagUrl": "https://flagcdn.com/aw.svg"
  },
  {
    "name": "호주",
    "enName": "Australia",
    "country": "AU",
    "nationNumber": "+61",
    "flagUrl": "https://flagcdn.com/au.svg"
  },
  {
    "name": "오스트리아",
    "enName": "Austria",
    "country": "AT",
    "nationNumber": "+43",
    "flagUrl": "https://flagcdn.com/at.svg"
  },
  {
    "name": "아제르바이잔",
    "enName": "Azerbaijan",
    "country": "AZ",
    "nationNumber": "+994",
    "flagUrl": "https://flagcdn.com/az.svg"
  },
  {
    "name": "바하마",
    "enName": "Bahamas",
    "country": "BS",
    "nationNumber": "+1242",
    "flagUrl": "https://flagcdn.com/bs.svg"
  },
  {
    "name": "방글라데시",
    "enName": "Bangladesh",
    "country": "BD",
    "nationNumber": "+880",
    "flagUrl": "https://flagcdn.com/bd.svg"
  },
  {
    "name": "바베이도스",
    "enName": "Barbados",
    "country": "BB",
    "nationNumber": "+1246",
    "flagUrl": "https://flagcdn.com/bb.svg"
  },
  {
    "name": "벨라루스",
    "enName": "Belarus",
    "country": "BY",
    "nationNumber": "+375",
    "flagUrl": "https://flagcdn.com/by.svg"
  },
  {
    "name": "벨기에",
    "enName": "Belgium",
    "country": "BE",
    "nationNumber": "+32",
    "flagUrl": "https://flagcdn.com/be.svg"
  },
  {
    "name": "벨리즈",
    "enName": "Belize",
    "country": "BZ",
    "nationNumber": "+501",
    "flagUrl": "https://flagcdn.com/bz.svg"
  },
  {
    "name": "베냉",
    "enName": "Benin",
    "country": "BJ",
    "nationNumber": "+229",
    "flagUrl": "https://flagcdn.com/bj.svg"
  },
  {
    "name": "버뮤다",
    "enName": "Bermuda",
    "country": "BM",
    "nationNumber": "+1441",
    "flagUrl": "https://flagcdn.com/bm.svg"
  },
  {
    "name": "부탄",
    "enName": "Bhutan",
    "country": "BT",
    "nationNumber": "+975",
    "flagUrl": "https://flagcdn.com/bt.svg"
  },
  {
    "name": "볼리비아",
    "enName": "Bolivia",
    "country": "BO",
    "nationNumber": "+591",
    "flagUrl": "https://flagcdn.com/bo.svg"
  },
  {
    "name": "보스니아 헤르체고비나",
    "enName": "Bosnia and Herzegovina",
    "country": "BA",
    "nationNumber": "+387",
    "flagUrl": "https://flagcdn.com/ba.svg"
  },
  {
    "name": "보츠와나",
    "enName": "Botswana",
    "country": "BW",
    "nationNumber": "+267",
    "flagUrl": "https://flagcdn.com/bw.svg"
  },
  {
    "name": "브라질",
    "enName": "Brazil",
    "country": "BR",
    "nationNumber": "+55",
    "flagUrl": "https://flagcdn.com/br.svg"
  },
  {
    "name": "영국령 버진아일랜드",
    "enName": "British Virgin Islands",
    "country": "VG",
    "nationNumber": "+1284",
    "flagUrl": "https://flagcdn.com/vg.svg"
  },
  {
    "name": "브루나이",
    "enName": "Brunei Darussalam",
    "country": "BN",
    "nationNumber": "+673",
    "flagUrl": "https://flagcdn.com/bn.svg"
  },
  {
    "name": "불가리아",
    "enName": "Bulgaria",
    "country": "BG",
    "nationNumber": "+359",
    "flagUrl": "https://flagcdn.com/bg.svg"
  },
  {
    "name": "부르키나파소",
    "enName": "Burkina Faso",
    "country": "BF",
    "nationNumber": "+226",
    "flagUrl": "https://flagcdn.com/bf.svg"
  },
  {
    "name": "캄보디아",
    "enName": "Cambodia",
    "country": "KH",
    "nationNumber": "+855",
    "flagUrl": "https://flagcdn.com/kh.svg"
  },
  {
    "name": "카메룬",
    "enName": "Cameroon",
    "country": "CM",
    "nationNumber": "+237",
    "flagUrl": "https://flagcdn.com/cm.svg"
  },
  {
    "name": "캐나다",
    "enName": "Canada",
    "country": "CA",
    "nationNumber": "+1",
    "flagUrl": "https://flagcdn.com/ca.svg"
  },
  {
    "name": "카보베르데",
    "enName": "Cape Verde",
    "country": "CV",
    "nationNumber": "+238",
    "flagUrl": "https://flagcdn.com/cv.svg"
  },
  {
    "name": "케이맨 제도",
    "enName": "Cayman Islands",
    "country": "KY",
    "nationNumber": "+1345",
    "flagUrl": "https://flagcdn.com/ky.svg"
  },
  {
    "name": "중앙아프리카 공화국",
    "enName": "Central African Republic",
    "country": "CF",
    "nationNumber": "+236",
    "flagUrl": "https://flagcdn.com/cf.svg"
  },
  {
    "name": "칠레",
    "enName": "Chile",
    "country": "CL",
    "nationNumber": "+56",
    "flagUrl": "https://flagcdn.com/cl.svg"
  },
  {
    "name": "콜롬비아",
    "enName": "Colombia",
    "country": "CO",
    "nationNumber": "+57",
    "flagUrl": "https://flagcdn.com/co.svg"
  },
  {
    "name": "코모로",
    "enName": "Comoros",
    "country": "KM",
    "nationNumber": "+269",
    "flagUrl": "https://flagcdn.com/km.svg"
  },
  {
    "name": "콩고",
    "enName": "Congo (Brazzaville)",
    "country": "CG",
    "nationNumber": "+242",
    "flagUrl": "https://flagcdn.com/cg.svg"
  },
  {
    "name": "콩고 민주 공화국",
    "enName": "Congo, (Kinshasa)",
    "country": "CD",
    "nationNumber": "+243",
    "flagUrl": "https://flagcdn.com/cd.svg"
  },
  {
    "name": "쿡 제도",
    "enName": "Cook Islands",
    "country": "CK",
    "nationNumber": "+682",
    "flagUrl": "https://flagcdn.com/ck.svg"
  },
  {
    "name": "코스타리카",
    "enName": "Costa Rica",
    "country": "CR",
    "nationNumber": "+506",
    "flagUrl": "https://flagcdn.com/cr.svg"
  },
  {
    "name": "크로아티아",
    "enName": "Croatia",
    "country": "HR",
    "nationNumber": "+385",
    "flagUrl": "https://flagcdn.com/hr.svg"
  },
  {
    "name": "퀴라소",
    "enName": "Curaçao",
    "country": "CW",
    "nationNumber": "+599",
    "flagUrl": "https://flagcdn.com/cw.svg"
  },
  {
    "name": "키프로스",
    "enName": "Cyprus",
    "country": "CY",
    "nationNumber": "+357",
    "flagUrl": "https://flagcdn.com/cy.svg"
  },
  {
    "name": "체코",
    "enName": "Czech Republic",
    "country": "CZ",
    "nationNumber": "+420",
    "flagUrl": "https://flagcdn.com/cz.svg"
  },
  {
    "name": "코트디부아르",
    "enName": "Côte d'Ivoire",
    "country": "CI",
    "nationNumber": "+225",
    "flagUrl": "https://flagcdn.com/ci.svg"
  },
  {
    "name": "덴마크",
    "enName": "Denmark",
    "country": "DK",
    "nationNumber": "+45",
    "flagUrl": "https://flagcdn.com/dk.svg"
  },
  {
    "name": "지부티",
    "enName": "Djibouti",
    "country": "DJ",
    "nationNumber": "+253",
    "flagUrl": "https://flagcdn.com/dj.svg"
  },
  {
    "name": "도미니카 공화국",
    "enName": "Dominica",
    "country": "DM",
    "nationNumber": "+1767",
    "flagUrl": "https://flagcdn.com/dm.svg"
  },
  {
    "name": "도미니카 공화국",
    "enName": "Dominican Republic",
    "country": "DO",
    "nationNumber": "+1849",
    "flagUrl": "https://flagcdn.com/do.svg"
  },
  {
    "name": "도미니카 공화국",
    "enName": "Dominican Republic",
    "country": "DO",
    "nationNumber": "+1829",
    "flagUrl": "https://flagcdn.com/do.svg"
  },
  {
    "name": "도미니카 공화국",
    "enName": "Dominican Republic",
    "country": "DO",
    "nationNumber": "+1809",
    "flagUrl": "https://flagcdn.com/do.svg"
  },
  {
    "name": "에콰도르",
    "enName": "Ecuador",
    "country": "EC",
    "nationNumber": "+593",
    "flagUrl": "https://flagcdn.com/ec.svg"
  },
  {
    "name": "이집트",
    "enName": "Egypt",
    "country": "EG",
    "nationNumber": "+20",
    "flagUrl": "https://flagcdn.com/eg.svg"
  },
  {
    "name": "엘살바도르",
    "enName": "El Salvador",
    "country": "SV",
    "nationNumber": "+503",
    "flagUrl": "https://flagcdn.com/sv.svg"
  },
  {
    "name": "적도 기니",
    "enName": "Equatorial Guinea",
    "country": "GQ",
    "nationNumber": "+240",
    "flagUrl": "https://flagcdn.com/gq.svg"
  },
  {
    "name": "에티오피아",
    "enName": "Ethiopia",
    "country": "ET",
    "nationNumber": "+251",
    "flagUrl": "https://flagcdn.com/et.svg"
  },
  {
    "name": "포클랜드 제도",
    "enName": "Falkland Islands (Malvinas)",
    "country": "FK",
    "nationNumber": "+500",
    "flagUrl": "https://flagcdn.com/fk.svg"
  },
  {
    "name": "페로 제도",
    "enName": "Faroe Islands",
    "country": "FO",
    "nationNumber": "+298",
    "flagUrl": "https://flagcdn.com/fo.svg"
  },
  {
    "name": "피지",
    "enName": "Fiji",
    "country": "FJ",
    "nationNumber": "+679",
    "flagUrl": "https://flagcdn.com/fj.svg"
  },
  {
    "name": "핀란드",
    "enName": "Finland",
    "country": "FI",
    "nationNumber": "+358",
    "flagUrl": "https://flagcdn.com/fi.svg"
  },
  {
    "name": "프랑스",
    "enName": "France",
    "country": "FR",
    "nationNumber": "+33",
    "flagUrl": "https://flagcdn.com/fr.svg"
  },
  {
    "name": "프랑스령 기아나",
    "enName": "French Guiana",
    "country": "GF",
    "nationNumber": "+594",
    "flagUrl": "https://flagcdn.com/gf.svg"
  },
  {
    "name": "가봉",
    "enName": "Gabon",
    "country": "GA",
    "nationNumber": "+241",
    "flagUrl": "https://flagcdn.com/ga.svg"
  },
  {
    "name": "감비아",
    "enName": "Gambia",
    "country": "GM",
    "nationNumber": "+220",
    "flagUrl": "https://flagcdn.com/gm.svg"
  },
  {
    "name": "조지아",
    "enName": "Georgia",
    "country": "GE",
    "nationNumber": "+995",
    "flagUrl": "https://flagcdn.com/ge.svg"
  },
  {
    "name": "독일",
    "enName": "Germany",
    "country": "DE",
    "nationNumber": "+49",
    "flagUrl": "https://flagcdn.com/de.svg"
  },
  {
    "name": "가나",
    "enName": "Ghana",
    "country": "GH",
    "nationNumber": "+233",
    "flagUrl": "https://flagcdn.com/gh.svg"
  },
  {
    "name": "지브롤터",
    "enName": "Gibraltar",
    "country": "GI",
    "nationNumber": "+350",
    "flagUrl": "https://flagcdn.com/gi.svg"
  },
  {
    "name": "그리스",
    "enName": "Greece",
    "country": "GR",
    "nationNumber": "+30",
    "flagUrl": "https://flagcdn.com/gr.svg"
  },
  {
    "name": "그린란드",
    "enName": "Greenland",
    "country": "GL",
    "nationNumber": "+299",
    "flagUrl": "https://flagcdn.com/gl.svg"
  },
  {
    "name": "그레나다",
    "enName": "Grenada",
    "country": "GD",
    "nationNumber": "+1473",
    "flagUrl": "https://flagcdn.com/gd.svg"
  },
  {
    "name": "과들루프",
    "enName": "Guadeloupe",
    "country": "GP",
    "nationNumber": "+590",
    "flagUrl": "https://flagcdn.com/gp.svg"
  },
  {
    "name": "과테말라",
    "enName": "Guatemala",
    "country": "GT",
    "nationNumber": "+502",
    "flagUrl": "https://flagcdn.com/gt.svg"
  },
  {
    "name": "건지 섬",
    "enName": "Guernsey",
    "country": "GG",
    "nationNumber": "+44",
    "flagUrl": "https://flagcdn.com/gg.svg"
  },
  {
    "name": "가이아나",
    "enName": "Guyana",
    "country": "GY",
    "nationNumber": "+592",
    "flagUrl": "https://flagcdn.com/gy.svg"
  },
  {
    "name": "아이티",
    "enName": "Haiti",
    "country": "HT",
    "nationNumber": "+509",
    "flagUrl": "https://flagcdn.com/ht.svg"
  },
  {
    "name": "온두라스",
    "enName": "Honduras",
    "country": "HN",
    "nationNumber": "+504",
    "flagUrl": "https://flagcdn.com/hn.svg"
  },
  {
    "name": "홍콩",
    "enName": "Hong Kong, SAR China",
    "country": "HK",
    "nationNumber": "+852",
    "flagUrl": "https://flagcdn.com/hk.svg"
  },
  {
    "name": "헝가리",
    "enName": "Hungary",
    "country": "HU",
    "nationNumber": "+36",
    "flagUrl": "https://flagcdn.com/hu.svg"
  },
  {
    "name": "인도",
    "enName": "India",
    "country": "IN",
    "nationNumber": "+91",
    "flagUrl": "https://flagcdn.com/in.svg"
  },
  {
    "name": "인도네시아",
    "enName": "Indonesia",
    "country": "ID",
    "nationNumber": "+62",
    "flagUrl": "https://flagcdn.com/id.svg"
  },
  {
    "name": "이라크",
    "enName": "Iraq",
    "country": "IQ",
    "nationNumber": "+964",
    "flagUrl": "https://flagcdn.com/iq.svg"
  },
  {
    "name": "아일랜드",
    "enName": "Ireland",
    "country": "IE",
    "nationNumber": "+353",
    "flagUrl": "https://flagcdn.com/ie.svg"
  },
  {
    "name": "맨섬",
    "enName": "Isle of Man",
    "country": "IM",
    "nationNumber": "+44",
    "flagUrl": "https://flagcdn.com/im.svg"
  },
  {
    "name": "이스라엘",
    "enName": "Israel",
    "country": "IL",
    "nationNumber": "+972",
    "flagUrl": "https://flagcdn.com/il.svg"
  },
  {
    "name": "이탈리아",
    "enName": "Italy",
    "country": "IT",
    "nationNumber": "+39",
    "flagUrl": "https://flagcdn.com/it.svg"
  },
  {
    "name": "자메이카",
    "enName": "Jamaica",
    "country": "JM",
    "nationNumber": "+1876",
    "flagUrl": "https://flagcdn.com/jm.svg"
  },
  {
    "name": "일본",
    "enName": "Japan",
    "country": "JP",
    "nationNumber": "+81",
    "flagUrl": "https://flagcdn.com/jp.svg"
  },
  {
    "name": "저지 섬",
    "enName": "Jersey",
    "country": "JE",
    "nationNumber": "+44",
    "flagUrl": "https://flagcdn.com/je.svg"
  },
  {
    "name": "요르단",
    "enName": "Jordan",
    "country": "JO",
    "nationNumber": "+962",
    "flagUrl": "https://flagcdn.com/jo.svg"
  },
  {
    "name": "카자흐스탄",
    "enName": "Kazakhstan",
    "country": "KZ",
    "nationNumber": "+77",
    "flagUrl": "https://flagcdn.com/kz.svg"
  },
  {
    "name": "카자흐스탄",
    "enName": "Kazakhstan",
    "country": "KZ",
    "nationNumber": "+76",
    "flagUrl": "https://flagcdn.com/kz.svg"
  },
  {
    "name": "케냐",
    "enName": "Kenya",
    "country": "KE",
    "nationNumber": "+254",
    "flagUrl": "https://flagcdn.com/ke.svg"
  },
  {
    "name": "한국",
    "enName": "Korea (South)",
    "country": "KR",
    "nationNumber": "+82",
    "flagUrl": "https://flagcdn.com/kr.svg"
  },
  {
    "name": "쿠웨이트",
    "enName": "Kuwait",
    "country": "KW",
    "nationNumber": "+965",
    "flagUrl": "https://flagcdn.com/kw.svg"
  },
  {
    "name": "키르기스스탄",
    "enName": "Kyrgyzstan",
    "country": "KG",
    "nationNumber": "+996",
    "flagUrl": "https://flagcdn.com/kg.svg"
  },
  {
    "name": "라오스",
    "enName": "Lao PDR",
    "country": "LA",
    "nationNumber": "+856",
    "flagUrl": "https://flagcdn.com/la.svg"
  },
  {
    "name": "라트비아",
    "enName": "Latvia",
    "country": "LV",
    "nationNumber": "+371",
    "flagUrl": "https://flagcdn.com/lv.svg"
  },
  {
    "name": "레바논",
    "enName": "Lebanon",
    "country": "LB",
    "nationNumber": "+961",
    "flagUrl": "https://flagcdn.com/lb.svg"
  },
  {
    "name": "레소토",
    "enName": "Lesotho",
    "country": "LS",
    "nationNumber": "+266",
    "flagUrl": "https://flagcdn.com/ls.svg"
  },
  {
    "name": "리비아",
    "enName": "Libya",
    "country": "LY",
    "nationNumber": "+218",
    "flagUrl": "https://flagcdn.com/ly.svg"
  },
  {
    "name": "리히텐슈타인",
    "enName": "Liechtenstein",
    "country": "LI",
    "nationNumber": "+423",
    "flagUrl": "https://flagcdn.com/li.svg"
  },
  {
    "name": "리투아니아",
    "enName": "Lithuania",
    "country": "LT",
    "nationNumber": "+370",
    "flagUrl": "https://flagcdn.com/lt.svg"
  },
  {
    "name": "룩셈부르크",
    "enName": "Luxembourg",
    "country": "LU",
    "nationNumber": "+352",
    "flagUrl": "https://flagcdn.com/lu.svg"
  },
  {
    "name": "마카오",
    "enName": "Macao, SAR China",
    "country": "MO",
    "nationNumber": "+853",
    "flagUrl": "https://flagcdn.com/mo.svg"
  },
  {
    "name": "북마케도니아",
    "enName": "Macedonia, Republic of",
    "country": "MK",
    "nationNumber": "+389",
    "flagUrl": "https://flagcdn.com/mk.svg"
  },
  {
    "name": "마다가스카르",
    "enName": "Madagascar",
    "country": "MG",
    "nationNumber": "+261",
    "flagUrl": "https://flagcdn.com/mg.svg"
  },
  {
    "name": "말라위",
    "enName": "Malawi",
    "country": "MW",
    "nationNumber": "+265",
    "flagUrl": "https://flagcdn.com/mw.svg"
  },
  {
    "name": "말레이시아",
    "enName": "Malaysia",
    "country": "MY",
    "nationNumber": "+60",
    "flagUrl": "https://flagcdn.com/my.svg"
  },
  {
    "name": "몰타",
    "enName": "Malta",
    "country": "MT",
    "nationNumber": "+356",
    "flagUrl": "https://flagcdn.com/mt.svg"
  },
  {
    "name": "모리셔스",
    "enName": "Mauritius",
    "country": "MU",
    "nationNumber": "+230",
    "flagUrl": "https://flagcdn.com/mu.svg"
  },
  {
    "name": "마요트",
    "enName": "Mayotte",
    "country": "YT",
    "nationNumber": "+262",
    "flagUrl": "https://flagcdn.com/yt.svg"
  },
  {
    "name": "멕시코",
    "enName": "Mexico",
    "country": "MX",
    "nationNumber": "+52",
    "flagUrl": "https://flagcdn.com/mx.svg"
  },
  {
    "name": "미크로네시아",
    "enName": "Micronesia, Federated States of",
    "country": "FM",
    "nationNumber": "+691",
    "flagUrl": "https://flagcdn.com/fm.svg"
  },
  {
    "name": "몰도바",
    "enName": "Moldova",
    "country": "MD",
    "nationNumber": "+373",
    "flagUrl": "https://flagcdn.com/md.svg"
  },
  {
    "name": "몽골국",
    "enName": "Mongolia",
    "country": "MN",
    "nationNumber": "+976",
    "flagUrl": "https://flagcdn.com/mn.svg"
  },
  {
    "name": "몬테네그로",
    "enName": "Montenegro",
    "country": "ME",
    "nationNumber": "+382",
    "flagUrl": "https://flagcdn.com/me.svg"
  },
  {
    "name": "몬트세랫",
    "enName": "Montserrat",
    "country": "MS",
    "nationNumber": "+1664",
    "flagUrl": "https://flagcdn.com/ms.svg"
  },
  {
    "name": "모로코",
    "enName": "Morocco",
    "country": "MA",
    "nationNumber": "+212",
    "flagUrl": "https://flagcdn.com/ma.svg"
  },
  {
    "name": "모잠비크",
    "enName": "Mozambique",
    "country": "MZ",
    "nationNumber": "+258",
    "flagUrl": "https://flagcdn.com/mz.svg"
  },
  {
    "name": "미얀마",
    "enName": "Myanmar",
    "country": "MM",
    "nationNumber": "+95",
    "flagUrl": "https://flagcdn.com/mm.svg"
  },
  {
    "name": "나미비아",
    "enName": "Namibia",
    "country": "NA",
    "nationNumber": "+264",
    "flagUrl": "https://flagcdn.com/na.svg"
  },
  {
    "name": "네팔",
    "enName": "Nepal",
    "country": "NP",
    "nationNumber": "+977",
    "flagUrl": "https://flagcdn.com/np.svg"
  },
  {
    "name": "네덜란드",
    "enName": "Netherlands",
    "country": "NL",
    "nationNumber": "+31",
    "flagUrl": "https://flagcdn.com/nl.svg"
  },
  {
    "name": "누벨칼레도니",
    "enName": "New Caledonia",
    "country": "NC",
    "nationNumber": "+687",
    "flagUrl": "https://flagcdn.com/nc.svg"
  },
  {
    "name": "뉴질랜드",
    "enName": "New Zealand",
    "country": "NZ",
    "nationNumber": "+64",
    "flagUrl": "https://flagcdn.com/nz.svg"
  },
  {
    "name": "니카라과",
    "enName": "Nicaragua",
    "country": "NI",
    "nationNumber": "+505",
    "flagUrl": "https://flagcdn.com/ni.svg"
  },
  {
    "name": "니제르",
    "enName": "Niger",
    "country": "NE",
    "nationNumber": "+227",
    "flagUrl": "https://flagcdn.com/ne.svg"
  },
  {
    "name": "나이지리아",
    "enName": "Nigeria",
    "country": "NG",
    "nationNumber": "+234",
    "flagUrl": "https://flagcdn.com/ng.svg"
  },
  {
    "name": "노퍽 섬",
    "enName": "Norfolk Island",
    "country": "NF",
    "nationNumber": "+672",
    "flagUrl": "https://flagcdn.com/nf.svg"
  },
  {
    "name": "노르웨이",
    "enName": "Norway",
    "country": "NO",
    "nationNumber": "+47",
    "flagUrl": "https://flagcdn.com/no.svg"
  },
  {
    "name": "오만",
    "enName": "Oman",
    "country": "OM",
    "nationNumber": "+968",
    "flagUrl": "https://flagcdn.com/om.svg"
  },
  {
    "name": "파키스탄",
    "enName": "Pakistan",
    "country": "PK",
    "nationNumber": "+92",
    "flagUrl": "https://flagcdn.com/pk.svg"
  },
  {
    "name": "팔레스타인",
    "enName": "Palestinian Territory",
    "country": "PS",
    "nationNumber": "+970",
    "flagUrl": "https://flagcdn.com/ps.svg"
  },
  {
    "name": "파나마",
    "enName": "Panama",
    "country": "PA",
    "nationNumber": "+507",
    "flagUrl": "https://flagcdn.com/pa.svg"
  },
  {
    "name": "파푸아뉴기니",
    "enName": "Papua New Guinea",
    "country": "PG",
    "nationNumber": "+675",
    "flagUrl": "https://flagcdn.com/pg.svg"
  },
  {
    "name": "파라과이",
    "enName": "Paraguay",
    "country": "PY",
    "nationNumber": "+595",
    "flagUrl": "https://flagcdn.com/py.svg"
  },
  {
    "name": "페루",
    "enName": "Peru",
    "country": "PE",
    "nationNumber": "+51",
    "flagUrl": "https://flagcdn.com/pe.svg"
  },
  {
    "name": "필리핀",
    "enName": "Philippines",
    "country": "PH",
    "nationNumber": "+63",
    "flagUrl": "https://flagcdn.com/ph.svg"
  },
  {
    "name": "폴란드",
    "enName": "Poland",
    "country": "PL",
    "nationNumber": "+48",
    "flagUrl": "https://flagcdn.com/pl.svg"
  },
  {
    "name": "포르투갈",
    "enName": "Portugal",
    "country": "PT",
    "nationNumber": "+351",
    "flagUrl": "https://flagcdn.com/pt.svg"
  },
  {
    "name": "푸에르토리코",
    "enName": "Puerto Rico",
    "country": "PR",
    "nationNumber": "+1939",
    "flagUrl": "https://flagcdn.com/pr.svg"
  },
  {
    "name": "푸에르토리코",
    "enName": "Puerto Rico",
    "country": "PR",
    "nationNumber": "+1787",
    "flagUrl": "https://flagcdn.com/pr.svg"
  },
  {
    "name": "카타르",
    "enName": "Qatar",
    "country": "QA",
    "nationNumber": "+974",
    "flagUrl": "https://flagcdn.com/qa.svg"
  },
  {
    "name": "루마니아",
    "enName": "Romania",
    "country": "RO",
    "nationNumber": "+40",
    "flagUrl": "https://flagcdn.com/ro.svg"
  },
  {
    "name": "러시아",
    "enName": "Russian Federation",
    "country": "RU",
    "nationNumber": "+79",
    "flagUrl": "https://flagcdn.com/ru.svg"
  },
  {
    "name": "러시아",
    "enName": "Russian Federation",
    "country": "RU",
    "nationNumber": "+78",
    "flagUrl": "https://flagcdn.com/ru.svg"
  },
  {
    "name": "러시아",
    "enName": "Russian Federation",
    "country": "RU",
    "nationNumber": "+75",
    "flagUrl": "https://flagcdn.com/ru.svg"
  },
  {
    "name": "러시아",
    "enName": "Russian Federation",
    "country": "RU",
    "nationNumber": "+74",
    "flagUrl": "https://flagcdn.com/ru.svg"
  },
  {
    "name": "러시아",
    "enName": "Russian Federation",
    "country": "RU",
    "nationNumber": "+73",
    "flagUrl": "https://flagcdn.com/ru.svg"
  },
  {
    "name": "르완다",
    "enName": "Rwanda",
    "country": "RW",
    "nationNumber": "+250",
    "flagUrl": "https://flagcdn.com/rw.svg"
  },
  {
    "name": "레위니옹",
    "enName": "Réunion",
    "country": "RE",
    "nationNumber": "+262",
    "flagUrl": "https://flagcdn.com/re.svg"
  },
  {
    "name": "세인트헬레나",
    "enName": "Saint Helena",
    "country": "SH",
    "nationNumber": "+247",
    "flagUrl": "https://flagcdn.com/sh.svg"
  },
  {
    "name": "세인트헬레나",
    "enName": "Saint Helena",
    "country": "SH",
    "nationNumber": "+290",
    "flagUrl": "https://flagcdn.com/sh.svg"
  },
  {
    "name": "세인트키츠 네비스",
    "enName": "Saint Kitts and Nevis",
    "country": "KN",
    "nationNumber": "+1869",
    "flagUrl": "https://flagcdn.com/kn.svg"
  },
  {
    "name": "세인트루시아",
    "enName": "Saint Lucia",
    "country": "LC",
    "nationNumber": "+1758",
    "flagUrl": "https://flagcdn.com/lc.svg"
  },
  {
    "name": "생피에르 미클롱",
    "enName": "Saint Pierre and Miquelon",
    "country": "PM",
    "nationNumber": "+508",
    "flagUrl": "https://flagcdn.com/pm.svg"
  },
  {
    "name": "세인트빈센트 그레나딘",
    "enName": "Saint Vincent and Grenadines",
    "country": "VC",
    "nationNumber": "+1784",
    "flagUrl": "https://flagcdn.com/vc.svg"
  },
  {
    "name": "생마르탱",
    "enName": "Saint-Martin (French part)",
    "country": "MF",
    "nationNumber": "+590",
    "flagUrl": "https://flagcdn.com/mf.svg"
  },
  {
    "name": "사모아",
    "enName": "Samoa",
    "country": "WS",
    "nationNumber": "+685",
    "flagUrl": "https://flagcdn.com/ws.svg"
  },
  {
    "name": "상투메 프린시페",
    "enName": "Sao Tome and Principe",
    "country": "ST",
    "nationNumber": "+239",
    "flagUrl": "https://flagcdn.com/st.svg"
  },
  {
    "name": "사우디아라비아",
    "enName": "Saudi Arabia",
    "country": "SA",
    "nationNumber": "+966",
    "flagUrl": "https://flagcdn.com/sa.svg"
  },
  {
    "name": "세네갈",
    "enName": "Senegal",
    "country": "SN",
    "nationNumber": "+221",
    "flagUrl": "https://flagcdn.com/sn.svg"
  },
  {
    "name": "세르비아",
    "enName": "Serbia",
    "country": "RS",
    "nationNumber": "+381",
    "flagUrl": "https://flagcdn.com/rs.svg"
  },
  {
    "name": "세이셸",
    "enName": "Seychelles",
    "country": "SC",
    "nationNumber": "+248",
    "flagUrl": "https://flagcdn.com/sc.svg"
  },
  {
    "name": "시에라리온",
    "enName": "Sierra Leone",
    "country": "SL",
    "nationNumber": "+232",
    "flagUrl": "https://flagcdn.com/sl.svg"
  },
  {
    "name": "싱가포르",
    "enName": "Singapore",
    "country": "SG",
    "nationNumber": "+65",
    "flagUrl": "https://flagcdn.com/sg.svg"
  },
  {
    "name": "슬로바키아",
    "enName": "Slovakia",
    "country": "SK",
    "nationNumber": "+421",
    "flagUrl": "https://flagcdn.com/sk.svg"
  },
  {
    "name": "슬로베니아",
    "enName": "Slovenia",
    "country": "SI",
    "nationNumber": "+386",
    "flagUrl": "https://flagcdn.com/si.svg"
  },
  {
    "name": "남아프리카",
    "enName": "South Africa",
    "country": "ZA",
    "nationNumber": "+27",
    "flagUrl": "https://flagcdn.com/za.svg"
  },
  {
    "name": "스페인",
    "enName": "Spain",
    "country": "ES",
    "nationNumber": "+34",
    "flagUrl": "https://flagcdn.com/es.svg"
  },
  {
    "name": "스리랑카",
    "enName": "Sri Lanka",
    "country": "LK",
    "nationNumber": "+94",
    "flagUrl": "https://flagcdn.com/lk.svg"
  },
  {
    "name": "수리남",
    "enName": "Suriname",
    "country": "SR",
    "nationNumber": "+597",
    "flagUrl": "https://flagcdn.com/sr.svg"
  },
  {
    "name": "에스와티니",
    "enName": "Swaziland",
    "country": "SZ",
    "nationNumber": "+268",
    "flagUrl": "https://flagcdn.com/sz.svg"
  },
  {
    "name": "스웨덴",
    "enName": "Sweden",
    "country": "SE",
    "nationNumber": "+46",
    "flagUrl": "https://flagcdn.com/se.svg"
  },
  {
    "name": "스위스",
    "enName": "Switzerland",
    "country": "CH",
    "nationNumber": "+41",
    "flagUrl": "https://flagcdn.com/ch.svg"
  },
  {
    "name": "대만",
    "enName": "Taiwan, Republic of China",
    "country": "TW",
    "nationNumber": "+886",
    "flagUrl": "https://flagcdn.com/tw.svg"
  },
  {
    "name": "탄자니아",
    "enName": "Tanzania, United Republic of",
    "country": "TZ",
    "nationNumber": "+255",
    "flagUrl": "https://flagcdn.com/tz.svg"
  },
  {
    "name": "태국",
    "enName": "Thailand",
    "country": "TH",
    "nationNumber": "+66",
    "flagUrl": "https://flagcdn.com/th.svg"
  },
  {
    "name": "동티모르",
    "enName": "Timor-Leste",
    "country": "TL",
    "nationNumber": "+670",
    "flagUrl": "https://flagcdn.com/tl.svg"
  },
  {
    "name": "토고",
    "enName": "Togo",
    "country": "TG",
    "nationNumber": "+228",
    "flagUrl": "https://flagcdn.com/tg.svg"
  },
  {
    "name": "통가",
    "enName": "Tonga",
    "country": "TO",
    "nationNumber": "+676",
    "flagUrl": "https://flagcdn.com/to.svg"
  },
  {
    "name": "트리니다드 토바고",
    "enName": "Trinidad and Tobago",
    "country": "TT",
    "nationNumber": "+1868",
    "flagUrl": "https://flagcdn.com/tt.svg"
  },
  {
    "name": "터키",
    "enName": "Turkey",
    "country": "TR",
    "nationNumber": "+90",
    "flagUrl": "https://flagcdn.com/tr.svg"
  },
  {
    "name": "투르크메니스탄",
    "enName": "Turkmenistan",
    "country": "TM",
    "nationNumber": "+993",
    "flagUrl": "https://flagcdn.com/tm.svg"
  },
  {
    "name": "터크스 케이커스 제도",
    "enName": "Turks and Caicos Islands",
    "country": "TC",
    "nationNumber": "+1649",
    "flagUrl": "https://flagcdn.com/tc.svg"
  },
  {
    "name": "우간다",
    "enName": "Uganda",
    "country": "UG",
    "nationNumber": "+256",
    "flagUrl": "https://flagcdn.com/ug.svg"
  },
  {
    "name": "우크라이나",
    "enName": "Ukraine",
    "country": "UA",
    "nationNumber": "+380",
    "flagUrl": "https://flagcdn.com/ua.svg"
  },
  {
    "name": "아랍에미리트",
    "enName": "United Arab Emirates",
    "country": "AE",
    "nationNumber": "+971",
    "flagUrl": "https://flagcdn.com/ae.svg"
  },
  {
    "name": "영국",
    "enName": "United Kingdom",
    "country": "GB",
    "nationNumber": "+44",
    "flagUrl": "https://flagcdn.com/gb.svg"
  },
  {
    "name": "미국",
    "enName": "United States of America",
    "country": "US",
    "nationNumber": "+1",
    "flagUrl": "https://flagcdn.com/us.svg"
  },
  {
    "name": "우루과이",
    "enName": "Uruguay",
    "country": "UY",
    "nationNumber": "+598",
    "flagUrl": "https://flagcdn.com/uy.svg"
  },
  {
    "name": "우즈베키스탄",
    "enName": "Uzbekistan",
    "country": "UZ",
    "nationNumber": "+998",
    "flagUrl": "https://flagcdn.com/uz.svg"
  },
  {
    "name": "베네수엘라",
    "enName": "Venezuela (Bolivarian Republic)",
    "country": "VE",
    "nationNumber": "+58",
    "flagUrl": "https://flagcdn.com/ve.svg"
  },
  {
    "name": "베트남",
    "enName": "Viet Nam",
    "country": "VN",
    "nationNumber": "+84",
    "flagUrl": "https://flagcdn.com/vn.svg"
  },
  {
    "name": "미국령 버진아일랜드",
    "enName": "Virgin Islands, US",
    "country": "VI",
    "nationNumber": "+1340",
    "flagUrl": "https://flagcdn.com/vi.svg"
  },
  {
    "name": "예멘",
    "enName": "Yemen",
    "country": "YE",
    "nationNumber": "+967",
    "flagUrl": "https://flagcdn.com/ye.svg"
  },
  {
    "name": "잠비아",
    "enName": "Zambia",
    "country": "ZM",
    "nationNumber": "+260",
    "flagUrl": "https://flagcdn.com/zm.svg"
  },
  {
    "name": "짐바브웨",
    "enName": "Zimbabwe",
    "country": "ZW",
    "nationNumber": "+263",
    "flagUrl": "https://flagcdn.com/zw.svg"
  }
]