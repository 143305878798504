import { BoardPost } from '@pixels/universal/model/board/board-post/board-post.schema';
import { PostCommentForUi } from '@pixels/universal/model/board/post-comment/post-comment.model';
import { BasicLoadMoreResult, BasicPagingResult, LoadMoreParam, Order, PagingParam } from '@pixels/universal/model/paging-model';
import { type CountryCode } from 'libphonenumber-js';

export type PostPagingResult = BasicPagingResult<BoardPost>;
export type PostLoadMoreResult = BasicLoadMoreResult<BoardPost>;

export interface BoardPostLoadMoreParam extends LoadMoreParam {
  categoryId?: string | null;
  countryCodes?: CountryCode[] | null;
}

export interface PostPagingParam extends PagingParam {
  order: Order;
  searchKeyword: string | undefined;
}

export interface PostLoadMoreParam extends LoadMoreParam {
  order: Order;
  searchKeyword: string | undefined;
}

export const BOARD_POST_TITLE_MAX_LENGTH = 200;
export const BOARD_POST_CONTENT_MAX_LENGTH = 10_000;
export const BOARD_POST_IMAGES_MAX_COUNT = 10;
export const BOARD_POST_VIDEOS_MAX_COUNT = 10;
export const BoardPostForUiKeys: (keyof BoardPost)[] = ['id', 'title', 'content', 'images', 'videos', 'commentsCount', 'likes', 'views', 'category', 'writer', 'createdAt', 'type', 'categoryId', 'writerId'];

export interface BoardPostForUi extends Pick<BoardPost, 'id' | 'title' | 'content' | 'images' | 'videos' | 'commentsCount' | 'likes' | 'views' | 'category' | 'categoryId' | 'writer' | 'writerId' | 'createdAt' | 'type'> {
  isLiked: boolean;
  isRead: boolean;
  isPostWriter: boolean;
  commentsForUi: PostCommentForUi[];
}

export enum BoardType {
  normal = 'normal',
  anonymous = 'anonymous',
}

export enum BoardPostParamKey {
  postId = 'postId',
  categoryId = 'categoryId',
}

export enum BoardPostQueryKey {
  directComment = 'directComment',
}

export function generateBoardPostPath(boardPostId: string): string {
  return `/posts/${boardPostId}`;
}

export function generateBoardPostsByCategory(categoryId: string): string {
  return `/posts/categories/${categoryId}`;
}


export const BOARD_POST_POPULARTY_SCORE_COMMENT = 5;
export const BOARD_POST_POPULARTY_SCORE_LIKE = 3;
export const BOARD_POST_POPULARTY_SCORE_VIEW = 1;
export const BOARD_POST_POPULARTY_SCORE_UNIQUE_VIEW = 2;
export const validateBoardPostImages = [(v: string[]): boolean => v.length <= BOARD_POST_IMAGES_MAX_COUNT, `첨부이미지는 최대 ${BOARD_POST_IMAGES_MAX_COUNT}개까지 가능합니다.`];
export const validateBoardPostVideos = [(v: string[]): boolean => v.length <= BOARD_POST_VIDEOS_MAX_COUNT, `첨부비디오는 최대 ${BOARD_POST_VIDEOS_MAX_COUNT}개까지 가능합니다.`];

export const EMPTY_TITLE = 'TITLE';


export interface VideoInfo {
  type: 'youtube' | 'file';
  id: string;
  url: string;
  width: number;
  height: number;
  ratio: number;
}
