import { EProviderId } from '@pixels/universal/model/firebase/firebase.model';
import { Role } from '@pixels/universal/model/user/role.enum';

export enum HChatParamKey {
  chatRoomId = 'chatRoomId',
}

export interface ChatUser {
  id: string;
  username: string;
  profileImageUrl: string;
  phoneNumber: string | undefined;
  email: string | undefined;
  roles: Role[];
  providers: ChatUserProviders;
}

export interface ChatUserProviders {
  [EProviderId.GOOGLE]?: boolean;
  [EProviderId.PHONE]?: boolean;
  [EProviderId.PASSWORD]?: boolean;
  [EProviderId.APPLE]?: boolean;
  [EProviderId.KAKAO]?: boolean;
  [EProviderId.KAKAO_WEB]?: boolean;
}

export const EMPTY_PROFILE_IMAGE_URL = 'https://cdn.pixelteam.io/instachat/common/dummy-profiles/dummy-profile.png';
export const EMPTY_PROFILE_IMAGE_URL_FOR_UNSELECT = 'https://cdn.pixelteam.io/instachat/common/dummy-profiles/unselect-profile-image.png';
export const EMPTY_CHAT_ROOM_IMAGE_URL = 'https://cdn.pixelteam.io/instachat/common/dummy-cover/dummy-chat-room-cover.png';

export const EMPTY_PROFILE_IMAGES_URL_FOR_MAN = [
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%81%EC%B2%A0.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EA%B4%91%EC%88%98.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%81%EC%8B%9D.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%81%EC%88%98.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%83%81%EC%B2%A0.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%81%ED%98%B8.png',
];

export const EMPTY_PROFILE_IMAGES_URL_FOR_WOMAN = [
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%88%9C%EC%9E%90.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%81%EC%88%99.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%81%EC%9E%90.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%98%A5%EC%88%9C.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%EC%A0%95%EC%88%99.png',
  'https://cdn.pixelteam.io/instachat/common/dummy-profiles/%ED%98%84%EC%88%99.png',
];

export const DUMMY_PROFILE_IMAGE_URLS = [
  ...EMPTY_PROFILE_IMAGES_URL_FOR_MAN, ...EMPTY_PROFILE_IMAGES_URL_FOR_WOMAN, EMPTY_PROFILE_IMAGE_URL
];

export function isDummyProfileImages(url: string): boolean {
  return DUMMY_PROFILE_IMAGE_URLS.includes(url);
}
