import { AppKeyTalklab } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';

const ProdFirebaseConfig = {
  apiKey: 'AIzaSyAROrEXz8BECDIifd_yRyUC7f5sL9rZQV8',
  authDomain: 'b6c-2f286.firebaseapp.com',
  projectId: 'b6c-2f286',
  storageBucket: 'b6c-2f286.appspot.com',
  messagingSenderId: '859369791594',
  appId: '1:859369791594:web:10c20195a03476ae44aa13',
  measurementId: 'G-EMKQ4NTSL4'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyAROrEXz8BECDIifd_yRyUC7f5sL9rZQV8',
  authDomain: 'b6c-2f286.firebaseapp.com',
  projectId: 'b6c-2f286',
  storageBucket: 'b6c-2f286.appspot.com',
  messagingSenderId: '859369791594',
  appId: '1:859369791594:web:10c20195a03476ae44aa13',
  measurementId: 'G-EMKQ4NTSL4'
};

export const TalklabProjectMeta: ProjectMeta = {
  id: AppKeyTalklab,
  mainPrimaryColor: '#000000',
  folderName: 'talklab-client',
  port: 8114,
  appId: 'io.pixelteam.preview',
  appStoreUrl: '',
  appName: '톡연구소',
  appSchemeName: 'Talklab',
  testTargetUrl: 'https://test-talklab.pixelteam.io',
  devTargetUrl: 'https://dev-talklab.pixelteam.io',
  prod: {
    androidAppId: '',
    iosAppId: '',
    iosEncodingAppId: '',
    webAppId: '',
    kakaoAppKey: '',
    googleReversedId: '',
    firebaseConfig: ProdFirebaseConfig,
    apiUrl: '',
  },
  dev: {
    androidAppId: '',
    iosAppId: '',
    iosEncodingAppId: '',
    webAppId: '',
    kakaoAppKey: '',
    googleReversedId: '',
    firebaseConfig: DevFirebaseConfig,
    apiUrl: 'https://dev-soulmate-api.pixelteam.io',
  },
  test: {
    androidAppId: '',
    iosAppId: '',
    iosEncodingAppId: '',
    webAppId: '',
    kakaoAppKey: '',
    googleReversedId: '',
    firebaseConfig: DevFirebaseConfig,
    apiUrl: 'https://test-soulmate-api.pixelteam.io',
  },
  consoleLogo: `
████████╗ █████╗ ██╗     ██╗  ██╗██╗      █████╗ ██████╗ 
╚══██╔══╝██╔══██╗██║     ██║ ██╔╝██║     ██╔══██╗██╔══██╗
   ██║   ███████║██║     █████╔╝ ██║     ███████║██████╔╝
   ██║   ██╔══██║██║     ██╔═██╗ ██║     ██╔══██║██╔══██╗
   ██║   ██║  ██║███████╗██║  ██╗███████╗██║  ██║██████╔╝
   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ 
`
};

