import { SchemaObjectMetadata } from './types/swagger-types';

export function ApiProperty(options: ApiPropertyOptions): PropertyDecorator {
  return () => {
  };
}

export function ApiPropertyOptional(options: ApiPropertyOptions): PropertyDecorator {
  return () => {
  };
}

export function getSchemaPath(model: string | Function): string {
  return '';
}

export interface ApiPropertyOptions
  extends Omit<SchemaObjectMetadata, 'name' | 'enum'> {
  name?: string;
  enum?: any[] | Record<string, any>;
  enumName?: string;
}
