import { ChatUserProviders } from '@pixels/universal/model/chat/chat-constants';

export enum EProviderId {
  APPLE = 'apple.com',
  KAKAO = 'oidc.kakao',
  KAKAO_WEB = 'oidc.kakao-web',
  GOOGLE = 'google.com',
  PHONE = 'phone',
  PASSWORD = 'password',
  FACEBOOK = 'facebook.com',
  TWITTER = 'twitter.com',
  GITHUB = 'github.com'
}

export interface FirebaseCustomTokenResponse {
  token: string;
}

export const EProviderIdsForKAKAO = [EProviderId.KAKAO, EProviderId.KAKAO_WEB];

export const SUPPORT_E_PROVIDER_ID = [EProviderId.PHONE, EProviderId.KAKAO_WEB, EProviderId.KAKAO, EProviderId.GOOGLE, EProviderId.APPLE];

export function getProviderToText(providerId: EProviderId): string {
  switch (providerId) {
    case EProviderId.KAKAO:
    case EProviderId.KAKAO_WEB:
      return '카카오';
    case EProviderId.APPLE:
      return '애플';
    case EProviderId.GOOGLE:
      return '구글';
    default:
      return '';
  }
}

export interface FUserRecord {
  /**
   * The user's `uid`.
   */
  readonly uid: string;
  /**
   * The user's primary email, if set.
   */
  readonly email?: string;
  /**
   * Whether or not the user's primary email is verified.
   */
  readonly emailVerified: boolean;
  /**
   * The user's display name.
   */
  readonly displayName?: string;
  /**
   * The user's photo URL.
   */
  readonly photoURL?: string;
  /**
   * The user's primary phone number, if set.
   */
  readonly phoneNumber?: string;
  /**
   * Whether or not the user is disabled: `true` for disabled; `false` for
   * enabled.
   */
  readonly disabled: boolean;

  /**
   * An array of providers (for example, Google, Facebook) linked to the user.
   */
  readonly providerData: FUserInfo[];
  /**
   * The user's hashed password (base64-encoded), only if Firebase Auth hashing
   * algorithm (SCRYPT) is used. If a different hashing algorithm had been used
   * when uploading this user, as is typical when migrating from another Auth
   * system, this will be an empty string. If no password is set, this is
   * null. This is only available when the user is obtained from
   * {@link BaseAuth.listUsers}.
   */
  readonly passwordHash?: string;
  /**
   * The user's password salt (base64-encoded), only if Firebase Auth hashing
   * algorithm (SCRYPT) is used. If a different hashing algorithm had been used to
   * upload this user, typical when migrating from another Auth system, this will
   * be an empty string. If no password is set, this is null. This is only
   * available when the user is obtained from {@link BaseAuth.listUsers}.
   */
  readonly passwordSalt?: string;
  /**
   * The user's custom claims object if available, typically used to define
   * user roles and propagated to an authenticated user's ID token.
   * This is set via {@link BaseAuth.setCustomUserClaims}
   */
  readonly customClaims?: {
    [key: string]: any;
  };
  /**
   * The ID of the tenant the user belongs to, if available.
   */
  readonly tenantId?: string | null;
  /**
   * The date the user's tokens are valid after, formatted as a UTC string.
   * This is updated every time the user's refresh token are revoked either
   * from the {@link BaseAuth.revokeRefreshTokens}
   * API or from the Firebase Auth backend on big account changes (password
   * resets, password or email updates, etc).
   */
  readonly tokensValidAfterTime?: string;

  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns A JSON-serializable representation of this object.
   */
  toJSON(): object;
}

export interface FUserInfo {
  /**
   * The user identifier for the linked provider.
   */
  readonly uid: string;
  /**
   * The display name for the linked provider.
   */
  readonly displayName: string;
  /**
   * The email for the linked provider.
   */
  readonly email: string;
  /**
   * The photo URL for the linked provider.
   */
  readonly photoURL: string;
  /**
   * The linked provider ID (for example, "google.com" for the Google provider).
   */
  readonly providerId: string;
  /**
   * The phone number for the linked provider.
   */
  readonly phoneNumber: string;

  /**
   * Returns a JSON-serializable representation of this object.
   *
   * @returns A JSON-serializable representation of this object.
   */
  toJSON(): object;
}

export interface CustomClaimParam {
  addProviderId?: EProviderId;
}

export function getValidAuthProviderId(chatUserProviders: ChatUserProviders, eProviderIds: EProviderId[] = []): EProviderId {
  const result = Object.entries(chatUserProviders).find(([providerId, value]) => !eProviderIds.includes(providerId as EProviderId) && value);
  return result ? result[0] as EProviderId : EProviderId.PHONE;
}

