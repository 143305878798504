export function generateChatRoomPath(chatRoomId: string): string {
  return `/chat-rooms/${chatRoomId}`;
}

export enum IChatParamKey {
  userId = 'userId',
  contentName = 'contentName'
}

export enum IChatQueryKey {
  message = 'message',
  videoChat = 'videoChat'
}
