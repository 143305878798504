import { Injectable, signal, WritableSignal } from '@angular/core';
import { BoardPostListType } from '@pixels/client/board/components/board-post-list/board-post-list.model';
import { AppInstallType } from '@pixels/client/components/app-banner/app-banner.model';
import { PATH_MAP } from '@pixels/client/one-on-one/core/constant';
import { AdminMenu } from '@pixels/client/pages/admin/admin.model';
import { isEmpty } from '@pixels/universal/es-toolkit-ext';
import { PRIVACY_AND_AGREEMENT_URL, PRIVACY_POLICY_URL } from '@pixels/universal/model/app-config/app-config.model';
import { AppConfig } from '@pixels/universal/model/app-config/schema/app-config.schema';
import { AppFontSize } from './pixels.model';

@Injectable({ providedIn: 'root' })
export abstract class AppInfo {
  // 필수 설정값
  public abstract readonly serviceName: string;
  public abstract readonly appType: string;
  public abstract readonly appStoreUrl: WritableSignal<string>;
  public abstract readonly playStoreUrl: WritableSignal<string>;

  // 옵션값
  public readonly unauthenticatedRedirectUrl: string | undefined = PATH_MAP.chatRooms.fullPath;
  public readonly homeFullPath = PATH_MAP.chatRooms.fullPath;
  public readonly boardPostListType: BoardPostListType = BoardPostListType.timeline;
  public readonly admobBannerListAppId: string = '';
  public readonly admobBannerChatRoomAppId: string = '';
  public readonly admobBannerVideoChatAppId: string = '';
  public readonly enabledReferralFriendNotification: boolean = true;
  public readonly enabledWaitingRoomInitGenderFilter: boolean = true;
  public readonly enabledApple: boolean = true;
  public readonly enabledKakao: boolean = true;
  public readonly enableNationNumber: boolean = true;
  public readonly enabledBoardPostsDefaultNation: boolean = true;
  public readonly enabledOpenChatsPostDefaultNation: boolean = true;
  public readonly enabledWaitingRoomsDefaultNation: boolean = true;
  public readonly needContentAgreement: boolean = false;
  public readonly loginTitle?: string;
  public readonly loginDescription?: string;
  public readonly loginAppIconUrl?: string;
  public readonly agreementAge: number = 14;
  public readonly emptyStoreUrl?: string;
  public readonly contentBaseUrl?: string;
  public readonly shareUrl?: string;
  public readonly enableAppBanner: boolean = false;
  public readonly showShareButton: boolean = false;
  public readonly fontSize: AppFontSize = AppFontSize.size1;
  // 이용 약관
  public readonly termsOfUsePath = '/static/agreement';
  public readonly smsCodeIssuePath = '/static/sms-code-issue';
  public readonly opensourcePath = '/static/opensource';
  // 운영 정책
  public readonly operationalPoliciesPath = '/static/operation';
  public readonly recommendedFriendsNotificationPath = PATH_MAP.recommendedFriendsNotification.fullPath;
  public readonly usernameSettingPath = PATH_MAP.usernameSetting.fullPath;
  public readonly appInstallPath = '/app-install';
  public readonly punishmentPath = '/punishment';
  public readonly loginPath = PATH_MAP.login.fullPath;
  public readonly unregisterCompletePath = PATH_MAP.unregisterComplete.fullPath;
  public readonly versionUpWaitingPath = PATH_MAP.versionUpWaiting.fullPath;
  public readonly underMaintenancePath = PATH_MAP.underMaintenance.fullPath;
  public readonly settingPath = PATH_MAP.mySetting.fullPath;
  public readonly bannerPlaceHolder: string = '';
  public readonly appInstallTitle: string = '';
  public readonly appInstallIconUrl: string = '';
  public readonly appInstallImageUrl: string = '';
  public readonly appInstallType: AppInstallType = AppInstallType.v1;
  public readonly adminMenus: AdminMenu[] = [];
  // 개인정보 수집 및 이용 동의문
  public readonly privacyAndAgreementUrl = signal(PRIVACY_AND_AGREEMENT_URL);
  // 개인정보 처리방침
  public readonly privacyPolicyUrl = signal(PRIVACY_POLICY_URL);

  public syncConfig(config: AppConfig): void {
    const { privacyAndAgreementUrl, privacyPolicyUrl, appStoreUrl, playStoreUrl } = config;
    if (privacyAndAgreementUrl) {
      this.privacyAndAgreementUrl.set(privacyAndAgreementUrl);
    }

    if (privacyPolicyUrl) {
      this.privacyPolicyUrl.set(privacyPolicyUrl);
    }

    if (!isEmpty(appStoreUrl)) {
      this.appStoreUrl.set(appStoreUrl);
    }

    if (!isEmpty(playStoreUrl)) {
      this.playStoreUrl.set(playStoreUrl);
    }
  }
}
