import { ApiProperty } from '@nestjs/swagger';
import { TalklabProjectMeta } from '../apps/project-meta/talklab-project-meta';

export class AppNotice {
  @ApiProperty({ description: '알림 제목', example: '알림' })
  title!: string;
  @ApiProperty({ description: '알림 내용 줄바꿈은 "\\n"', example: '새로운 앱이 출시되었습니다.\n플레이스토어에서 업데이트하세요.' })
  content!: string;
  @ApiProperty({ description: '긍정 버튼 레이블', example: '이동' })
  okText!: string;
  @ApiProperty({ description: '이동할 링크 URL', example: `https://play.google.com/store/apps/details?id=${TalklabProjectMeta.appId}` })
  linkUrl!: string;
  @ApiProperty({ description: '부정 버튼 레이블', required: false })
  cancelText?: string | undefined;
}

export const PRIVACY_AND_AGREEMENT_URL = 'https://plip.kr/pcc/4e7e0f83-5ec4-4160-8223-0658b49b31e5/consent/6.html';
export const PRIVACY_POLICY_URL = 'https://plip.kr/pcc/4e7e0f83-5ec4-4160-8223-0658b49b31e5/privacy/3.html';

export const DEFAULT_APP_CONFIG = {
  minimumVersion: '1.0.0',
  isUnderMaintenance: false,
  showGenderBirthSkipByAndroid: false,
  showGenderBirthSkipByIOS: false,
  showGenderBirthSkipByWeb: false,
  privacyAndAgreementUrl: PRIVACY_AND_AGREEMENT_URL,
  privacyPolicyUrl: PRIVACY_POLICY_URL,
};
