import { InjectionToken } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PlatformType } from '@pixels/universal/model/chat/question/question.model';

export enum QuestionPageType {
  form = 'form',
  list = 'list',
  view = 'view',
}

export interface MyQuestionFormParam {
  title: FormControl<string>;
  content: FormControl<string>;
  appVersion: FormControl<string>;
  osVersion: FormControl<string>;
  platformType: FormControl<PlatformType>;
  deviceName: FormControl<string>;
  webViewVersion: FormControl<string>;
}

export const MyQuestionViewTypeQueryParamName = 'questionViewType';
export const QuestionIdParamKey = 'questionId';

export type QuestionDetailPathType = (id: string) => string;
export const QUESTION_DETAIL_PATH_TOKEN = new InjectionToken<QuestionDetailPathType>('질문하기 주소', {
  providedIn: 'root',
  factory: () => (id: string): string => `/questions/${id}`
});
