import nationItems from '@pixels/universal/model/nation/nation-numbers.json';
import { type CountryCode } from 'libphonenumber-js';

export interface NationItem {
  name: string;
  enName: string;
  nationNumber: string;
  flagUrl: string;
  country: string;
}

const nationItemMapByCountryCode = new Map<CountryCode, NationItem>();
const nationItemsByCountryCode: NationItem[] = [];

export const KOREA_COUNTRY_CODE = 'KR';
export const USA_COUNTRY_CODE = 'US';

export function getNationItemsByNationNumber(): NationItem[] {
  return nationItems;
}

export function getNationItemsByCountryCode(): NationItem[] {
  if (nationItemsByCountryCode.length === 0) {
    const countryCodes = new Map<string, boolean>();
    for (const nationNumber of getNationItemsByNationNumber()) {
      const countryCode = nationNumber.country;
      if (!countryCodes.has(countryCode)) {
        countryCodes.set(countryCode, true);
        nationItemsByCountryCode.push(nationNumber);
      }
    }
  }
  return nationItemsByCountryCode;
}

export function getNationItemByCountryCode(): Map<CountryCode, NationItem> {
  if (nationItemMapByCountryCode.size === 0) {
    for (const nationItem of getNationItemsByCountryCode()) {
      nationItemMapByCountryCode.set(nationItem.country as CountryCode, nationItem);
    }
  }
  return nationItemMapByCountryCode;
}
