// app key 는 dashed-style 로 작성함

export const AppKeyImsolo = 'imsolo';
export const AppKeyInstaChat = 'insta-chat';
export const AppKeyKpop = 'kpop';
export const AppKeyMbti = 'mbti';
export const AppKeyChatKorean = 'chat-korean';
export const AppKeyAiChat = 'ai-chat';
export const AppKeyChristian = 'christian';
export const AppKeySingles = 'singles';
export const AppKeyAgain = 'again';
export const AppKeySoulmate = 'soulmate';
export const AppKeyTalklab = 'talklab';
export const AppKeyPixelsAdmin = 'pixels-admin';
export const AppKeyAppManager = 'app-manager';
export const AppKeyFlexLayout = 'flex-layout';
export const AppKeyFlynnlab = 'flynnlab';
export const AppKeyGiftMap = 'gift-map';
export const AppKeyHomeVideo = 'home-video';
export const AppKeyKdramaChat = 'kdrama-chat';
export const AppKeyNtools = 'ntools';
export const AppKeyOpenChat = 'open-chat';
export const AppKeyPixelteam = 'pixelteam';
export const AppKeyPixels = 'pixels';
export const AppKeyFacetype = 'facetype';
export const AppKeyRecruit = 'recruit';
export const AppKeyNewgle = 'newgle';
export const AppKeyReleaseNote = 'release-note';
export const AppKeyA00 = 'a00';
export const AppKeyABiz = 'biz';

export const ONE_ON_ONE_TYPES = new Set([
  AppKeyAgain, AppKeyChristian, AppKeyKdramaChat, AppKeyChatKorean, AppKeySingles, AppKeyAiChat,
  AppKeyInstaChat, AppKeyImsolo, AppKeyKpop, AppKeyMbti, AppKeySoulmate,
  AppKeyNewgle
]);

export const SUPPORTED_LOCATIONS = [AppKeySoulmate, AppKeyInstaChat, AppKeyAgain, AppKeySingles, AppKeyChristian, AppKeyNewgle];
